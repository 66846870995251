<template>
    <div class="main">
        <!-- 头部 -->
        <div class="head">
            <van-image height="25vh" src="http://huidazhe.vip/static/img/top.b3d9413.png" fit="contain"/>
        </div>
        <div class="content">
            <!-- 兑换码输入框 -->
            <van-field
                class="content__rights-no-field"
                v-model="rightsNo"
                placeholder="请输入兑换码"
            />
            <!-- 查看兑换权益按钮 -->
            <van-button
                class="content__rights-no-btn"
                block
                round
                type="primary"
                color="#f0d4c0"
                @click="fetchRightsInfo(rightsNo)"
                :loading="loading"
            >
                立即使用
            </van-button>
        </div>
    </div> 
</template>

<script>
import {Button, Image, Row, Col, Field} from "vant";
import request from "@/utils/request.js";

export default {
    name: "Ticket",
    components: {
        [Button.name]: Button,
        [Image.name]: Image,
        [Row.name]: Row,
        [Col.name]: Col,
        [Field.name]: Field
    },
    data() {
        return {
            rightsNo: "",
            loading: false
        };
    },
    mounted() {
        const rightsNo = this.$route.params.rightsNo;
        if (rightsNo) {
            this.rightsNo = rightsNo;
        }
    },
    methods: {
        async isCompositeRights(rightsType) {
            if (rightsType === 'COMPOSITE' || rightsType === 'ONE_OF_TWO' || rightsType === 'ONE_OF_THREE' || rightsType === 'TWO_OF_THREE') {
                console.log('ssdsfadfs');
                return true;
            }
            console.log(rightsType);
            return false;
        },
        async fetchRightsInfo(rightsNo) {
            this.loading = true;

            try {
                const {data: result} = await request.get(`/rights?rightsNo=${rightsNo}`);
                if (result.rightsState === 'UNUSED') {
                    // 直接兑换商品类型的权益券
                    if (result.rightsType === 'GOODS') {
                        
                        // 直充类商品跟其他类型的商品区分开跳转
                        if (result.goodsType === 'DIRECT') {

                            this.$router.push(`/input-account/${result.rightsNo}?skuName=${result.skuName}`);

                        } else {
                            // 卡密类商品，直接下单，用权益券兑换卡密
                            const {data: exchangeResult} = await request.post('/rights/exchange', {
                                data: {
                                    rightsNos: [result.rightsNo]
                                }
                            });
                            const card = exchangeResult.cardList[0];
                            this.$router.push(`/ticket/${card.account}`);
                        }
                    } else if (this.isCompositeRights(result.rightsType)) {
                        this.$router.push(`/composite/rights/${rightsNo}`);
                    }
                } else {
                    // 已使用
                    if (result.rightsType === 'GOODS') {
                        if (result.goodsType !== 'DIRECT') {
                            this.$router.push(`/ticket/${result.cardPairList[0].account}`);
                        } else {
                            this.$router.push(`/rights-result/${rightsNo}`); 
                        }
                    } else if (this.isCompositeRights(result.rightsType)) {
                        this.$router.push(`/composite/rights/${rightsNo}`);
                    }
                }
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="less">
@color-vice: #f0d4c0;
@color-vice-font: #ffdfc3;
@color-vice-weight: #4d3933;

@color-theme: #17161c;
@color-border:#00000026;
@font-size-title: 16px;
@font-weight-title: 500;
@line-height-title: 24px;
@font-color-title: #000000d9;

@font-size-normal: 14px;
@font-weight-normal: 400;
@line-height-normal: 22px;
@font-color-normal: #000000a6;

.head {
    background-color: @color-vice;
    text-align: center;
}

.content {
    height: 75vh;
    background-color: rgb(253, 245, 234);
    padding: 24px 32px 0 32px;
}

.content__rights-no-field {
    border-radius: 8px;
}

.content__rights-no-btn.van-button--primary {
    margin-top: 24px;
    font-weight: 700;
    border: 1px solid @color-border;
    color: @color-theme!important;
}

.content__step {
    margin-top: 24px;
    border: 1px solid @color-vice-weight;
    padding: 16px 8px;
    border-radius: 8px;
}

.content__step-item {
    margin: 24px 0;
}

.content__step-title {
    color: @color-theme;
    font-weight: 200!important;
    font-size: @font-size-normal;
    padding: 1px 0;
    border-radius: 4px;
    line-height: 14px;
}
.content__step-info {
    padding-left: 16px;
    color: @color-theme;
    font-size: 12px;
}

</style>